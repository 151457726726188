import styled from "styled-components";
import "./Keyboard.css";

interface Props {
  o: {
    clickfunc: Function;
    letters: any;
  };
}
enum State {
  correct = "correct",
  wrongPosition = "wrongPosition",
  incorrect = "incorrect",
  empty = "empty",
}



const Keyboard = (props: Props) => {
  let firstrow = ["Q", "W", "E", "R", "T", "Z", "U", "I", "O", "P"];
  let secondrow = ["A", "S", "D", "F", "G", "H", "J", "K", "L"];
  let thirdrow = ["Y", "X", "C", "V", "B", "N", "M"];

  let clickfunc = props.o.clickfunc;
  let letters = props.o.letters;
  return (
    <KeyboardWrapper id="Keyboard">
      <KeyRow>
        {firstrow.map((letter: string) => {
          let color = "#A2A2A2DF";
          if (letters[letter] === State.correct){
            color = "#4FB258DF";
          }
          if (letters[letter] === State.wrongPosition){
            color = "#F5C300DF";
          }
          if (letters[letter] === State.incorrect){
            color = "#E34B35DF";
          }
          return (
            <Key color={color} onClick={() => clickfunc(letter)} key={letter}>
              {letter}
            </Key>
          );
        })}
      </KeyRow>
      <KeyRow>
        {secondrow.map((letter: string) => {
          let color = "#A2A2A2DF";
          if (letters[letter] === State.correct){
            color = "#4FB258DF";
          }
          if (letters[letter] === State.wrongPosition){
            color = "#F5C300DF";
          }
          if (letters[letter] === State.incorrect){
            color = "#E34B35DF";
          }
          return (
            <Key color={color} onClick={() => clickfunc(letter)} key={letter}>
              {letter}
            </Key>
          );
        })}
      </KeyRow>
      <KeyRow>
        <SpecialKey color="#A2A2A2DF" onClick={() => clickfunc("return")}>
          Enter
        </SpecialKey>
        {thirdrow.map((letter: string) => {
          let color = "#A2A2A2DF";
          if (letters[letter] === State.correct){
            color = "#4FB258DF";
          }
          if (letters[letter] === State.wrongPosition){
            color = "#F5C300DF";
          }
          if (letters[letter] === State.incorrect){
            color = "#E34B35DF";
          }
          return (
            <Key color={color} onClick={() => clickfunc(letter)} key={letter}>
              {letter}
            </Key>
          );
        })}
        <SpecialKey color="#A2A2A2DF" onClick={() => clickfunc("delete")}>
          Delete
        </SpecialKey>
      </KeyRow>
    </KeyboardWrapper>
  );
};

const Key = styled.button`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: black;
  font-weight: 500;
  width: min(8vw, 55px);
  height: min(8vw, 55px);
  aspect-ratio: 1;
  padding-top: 100%;
  padding: 0.5rem 0.5rem;
  border: none;
  background-color: ${(props) => props.color};
  font-size: min(4vw, 160%);
  margin: 0.2rem;
  outline: none;
  font-family: "Trebuchet MS", sans-serif;
  border-radius: 20%;

  user-select: none;
`;

const SpecialKey = styled(Key)`
  width: min(12vw, 77px);
  height: min(8vw, 55px);
  aspect-ratio: 1.4;
`;

const KeyRow = styled.div`
  display: flex;
  justify-content: center;
`;

const KeyboardWrapper = styled.div`
  width = min(100%, 500px);
`

export default Keyboard;
