import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "./App.css";
import Keyboard from "./Keyboard";
import KeyboardEventHandler from "react-keyboard-event-handler";
import Dialog from "./EndDialog";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { Store } from "react-notifications-component";

import data from "./data.json";

//const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

enum State {
  correct = "correct",
  wrongPosition = "wrongPosition",
  incorrect = "incorrect",
  empty = "empty",
}

interface Field {
  letter: string;
  state: string;
}

interface Props {
  o?: {
    color: string;
    opacity: string;
  };
}

let active_field: [number, number] = [0, 0];
let words: Array<string> = [];

const { solutions } = data;
const { legal_words } = data;

let solution = solutions[Math.floor(Math.random() * solutions.length)];
//let solution = "FONDS";

let letters_start: any = {
  A: State.empty,
  B: State.empty,
  C: State.empty,
  D: State.empty,
  E: State.empty,
  F: State.empty,
  G: State.empty,
  H: State.empty,
  I: State.empty,
  J: State.empty,
  K: State.empty,
  L: State.empty,
  M: State.empty,
  N: State.empty,
  O: State.empty,
  P: State.empty,
  Q: State.empty,
  R: State.empty,
  S: State.empty,
  T: State.empty,
  U: State.empty,
  V: State.empty,
  W: State.empty,
  X: State.empty,
  Y: State.empty,
  Z: State.empty,
};

const App = () => {
  const [wordGrid, setWordGrid] = useState<Array<Array<Field>>>([]);
  const [letters, setLetters] = useState<any>({ ...letters_start });
  const [disabled, setDisabled] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [won, setWon] = useState<boolean>(false);
  const [result, setResult] = useState<string>("");

  function checkWord(word: string) {
    let states: Array<Field> = [];
    let newLetters = letters;
    let solution_list = solution.split("");

    for (let i = 0; i < word.length; i++) {
      let letter = word[i];
      let letters_at_right_pos = word
        .split("")
        .map(
          (e, idx) => e === solution[idx] && letter === e // word contains letter at right position
        )
        .filter((e) => e === true).length; // HOW many letters are at right position

      let letter_in_word = solution
        .split("")
        .filter((x) => x === letter).length;

      ////////////////////////////////////////////////////////////////
      ////////////////////////////////////////////////////////////////

      if (solution[i] === letter) {
        // word contains letter at right position: ;
        solution_list.splice(solution_list.indexOf(letter), 1); // remove letter from solution list

        states.push({ letter: letter, state: State.correct });
        newLetters[letter] = State.correct;
      }
      ////////////////////////////////////////////////////////////////
      ////////////////////////////////////////////////////////////////
      else if (
        solution.includes(letter) &&
        !(letters_at_right_pos >= letter_in_word) &&
        solution_list.includes(letter)
      ) {
        // word contains letter but not at right position and there are less letters of letter marked than exist;
        solution_list.splice(solution_list.indexOf(letter), 1); // remove letter from solution list
        states.push({ letter: letter, state: State.wrongPosition });
        if (newLetters[letter] !== State.correct) {
          newLetters[letter] = State.wrongPosition;
        }
      }
      ///////////////////////////////////////////////////////////////
      ///////////////////////////////////////////////////////////////
      else {
        states.push({ letter: letter, state: State.incorrect });
        if (
          newLetters[letter] !== State.correct &&
          newLetters[letter] !== State.wrongPosition
        ) {
          newLetters[letter] = State.incorrect;
        }
      }
    }

    setLetters(newLetters);
    return states;
  }

  const initWordGrid = () => {
    let newWordGrid = [];
    for (let i = 0; i < 6; i++) {
      let test = [];
      for (let j = 0; j < 5; j++) {
        test.push({ letter: " ", state: State.empty }); // states = "correct", "worngposition", "incorrect", "empty"
      }
      newWordGrid.push(test);
    }
    setWordGrid(newWordGrid.slice());
  };

  useEffect(() => {
    if (wordGrid.length === 0) initWordGrid();
  });

  const endGame = async (tries: number) => {
    setDisabled(true);
    let res = "";
    wordGrid.forEach((row) => {
      row.forEach((field) => {
        if (field.state === State.correct) res += "🟩";
        else if (field.state === State.wrongPosition) res += "🟨";
        else if (field.state === State.incorrect) res += "🟥";
        else res += "⬛";
      });
      res += "\n";
    });
    setResult(res);
    setModalOpen(true);
  };

  const resetGame = () => {
    setModalOpen(false);
    initWordGrid();
    active_field = [0, 0];
    words = [];
    setResult("");
    solution = solutions[Math.floor(Math.random() * solutions.length)];
    setLetters({ ...letters_start });
    setDisabled(false);
    setWon(false);
  };

  function click(letter: string) {
    if (disabled) return;
    if (letter === "delete" || letter === "Delete" || letter === "backspace") {
      let newWorldGrid = wordGrid.slice();
      if (active_field[1] >= 1) {
        active_field = [active_field[0], active_field[1] - 1];
      } else if (active_field[0] >= 1 && !(words.length >= active_field[1])) {
        active_field = [active_field[0] - 1, 4];
      }

      newWorldGrid[active_field[0]][active_field[1]] = {
        letter: " ",
        state: State.empty,
      };
      setWordGrid(newWorldGrid.slice());
      return;
    } else if (letter === "return" || letter === "Return") {
      if (active_field[1] + 1 > 5) {
        let word = "";
        wordGrid[active_field[0]].forEach((field: Field) => {
          word = word + field.letter;
        });
        if (legal_words.includes(word)) {
          words.push(word);
          let newWordGrid = wordGrid.slice();
          let states = checkWord(word);

          for (var i = 0; i < states.length; i++) {
            newWordGrid[active_field[0]][i] = states[i];
          }
          setWordGrid(newWordGrid.slice());
          active_field = [active_field[0] + 1, 0];
          if (states.every((e) => e.state === State.correct)) {
            setWon(true);
            endGame(active_field[0]);
          }
        }
        if (active_field[0] === 6 && word !== solution) {
          endGame(active_field[0]);
        }if (!legal_words.includes(word)) {
          console.log("ungültig");
          Store.addNotification({
            title: "Ungültig",
            message: "Bitte geben Sie ein neues Wort ein",
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 1000,
              onScreen: false,
            },
          });
        }
      }

      return;
    }

    if (active_field[1] <= 4) {
      active_field = [active_field[0], active_field[1] + 1];
    }

    if (wordGrid[active_field[0]][active_field[1] - 1].letter === " ") {
      let newWordGrid = wordGrid.slice();
      newWordGrid[active_field[0]][active_field[1] - 1] = {
        letter: letter,
        state: State.empty,
      };
      setWordGrid(newWordGrid.slice());
    }
  }

  return (
    <>
      <ReactNotifications />
      <Div>
        {wordGrid.map((row: Array<any>, idx: number) => {
          return (
            <Row key={idx}>
              {row.map((col: Field, idx: number) => {
                let color = "#F2F2F222";
                if (col.state === State.correct) {
                  color = "#3dad46FF";
                } else if (col.state === State.wrongPosition) {
                  color = "#F5C300FF";
                } else if (col.state === State.incorrect) {
                  color = "#E34B35FF";
                }
                return (
                  <Letter color={color} key={idx}>
                    {col.letter}
                  </Letter>
                );
              })}
            </Row>
          );
        })}
        <Keyboard o={{ clickfunc: click, letters: letters }} />
        <KeyboardEventHandler
          handleKeys={[
            ...Object.keys(letters),
            "Return",
            "Delete",
            "backspace",
          ]}
          onKeyEvent={(key, e) => click(key)}
        />
      </Div>
      <Dialog
        open={modalOpen}
        tries={active_field[0]}
        onClose={() => resetGame()}
        result={result}
        won={won}
        solution={solution}
      ></Dialog>
    </>
  );
};

const Div = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 30px;
  margin-bottom: 10px;
  width: auto;
  height: auto;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;

  gap: 0.5rem 0.3rem;
  width: 100vw;
`;

const Letter = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: stretch;

  align-items: center;
  font-size: min(8vw, 300%);
  background-color: ${(props) => props.color};

  width: min(10vw, 10vh);
  height: min(10vw, 10vh);

  border-radius: 8px;
  //padding: 0.5rem 1.2rem;
  margin: 0.1rem 0.1rem;
  font-family: "Trebuchet MS", sans-serif;
  color: #ffffff;

  border-color: gray;
  border-width: 2px;
  border-style: solid;

  user-select: none;
`;

export default App;
