import ReactDom from "react-dom";
import styled from "styled-components";
import { Store } from "react-notifications-component";

interface Props {
  open: boolean;
  tries: number;
  onClose: Function;
  result: string;
  won: boolean;
  solution: string;
}

const Dialog = (props: Props) => {
  let open = props.open;
  if (!open) {
    return null;
  }

  function share(message: string) {
    try{
    navigator.clipboard.writeText(message).then(
      function () {
        Store.addNotification({
          title: "Ergebnis kopiert",
          type: "info",
          insert: "top",
          container: "top-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      },
      function () {
        Store.addNotification({
          title: "Failed",
          type: "info",
          insert: "top",
          container: "top-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      }
    );
    props.onClose();
  }
    catch (error) {
      props.onClose();
    }
  }

  if (!props.won) {
    return ReactDom.createPortal(
    <>
      <Div/>
      <DialogWraper>
        <CloseButton onClick={() => props.onClose()}>X</CloseButton>
        <Text>
          Die Lösung wäre gewesen: 
          
        </Text>
        <Text>{props.solution}</Text>
      </DialogWraper>
    </>, 
    document.getElementById("portal")!);
  }
  return ReactDom.createPortal(
    <>
      <Div />
      <DialogWraper>
        <CloseButton onClick={() => props.onClose()}>X</CloseButton>
        <Text>
          {props.result.split(/\n/).map((line, idx) => (
            <div key={idx}>{line}</div>
          ))}
        </Text>
        <Button onClick={() => share(props.result)}>Teilen</Button>
      </DialogWraper>
    </>,
    document.getElementById("portal")!
  );
};

const DialogWraper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: #282c34dd;
  padding: 1rem;
  z-index: 1000;
  //color: #a2a2a2;
`;

const Div = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000aa;
  z-index: 100;
`;
const Button = styled.button`
  height: 40%;
  width: 50%;

  background-color: #424755dd;
  text-align: center;
  border-radius: 10px;

  font-size: 20px;
  color: #fff;

  padding: 1px;
  border: 10px;
  border-width: 1px solid;
  border-color: #fff;
  margin-top: min(5%, 50px)
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;

  font-size: auto;
  color: #000;

  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #424755dd;

  margin:min(2%, 10px);
`;
const Text = styled.div`
  color: #e2e5eedd;
  font-size: min(4vw, 300%);
  text-align: center;
  user-select: none;
`;

export default Dialog;
